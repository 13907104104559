import React from "react"
import { navigate } from "gatsby-link"
import { Container, Form, Button } from "react-bootstrap"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <SEO title="Contato" />
        <Container>
          <h3 className="my-4 cl-identity">Entre em contato comigo!</h3>
          <Form
            name="contact"
            method="post"
            action="/contato/obrigada/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>

            <Form.Group>
              <Form.Label htmlFor={"name"} className="cl-identity">
                Seu nome
              </Form.Label>
              <Form.Control
                type={"text"}
                name={"name"}
                onChange={this.handleChange}
                id={"name"}
                required={true}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor={"email"} className="cl-identity">
                Seu e-mail
              </Form.Label>
              <Form.Control
                type={"email"}
                name={"email"}
                onChange={this.handleChange}
                id={"email"}
                required={true}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor={"message"} className="cl-identity">
                Escreva sua mensagem
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name={"message"}
                onChange={this.handleChange}
                id={"message"}
                required={true}
              />
            </Form.Group>

            <Form.Group>
              <Button
                className="cl-fundo border-0 bg-identity hovbg-darkviolet"
                type="submit"
              >
                Enviar
              </Button>
            </Form.Group>
          </Form>
        </Container>
      </Layout>
    )
  }
}
